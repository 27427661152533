<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <clinicSelector />
    </v-container>
    <v-container fluid class="smy_contain">
      <v-row dense no-gutters class="table-header">
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0" cols="6">
          <h2>{{ card_title }}</h2>
        </v-col>
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0 d-flex justify-end" cols="6">
          <div style="width: 300px; display:inline-block">
            <v-text-field
              v-model="search"
              append-icon="$riwIconSet_search"
              label="Search"
              single-line
              hide-details
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <v-btn class="ml-6" depressed outlined color="primary" @click="initNew">{{ createNewBtnTitle }}</v-btn>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col class="pb-0 mb-0 mt-0 pt-0" cols="12">
          <v-card class="flat">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loadingTable"
            >
              <template v-slot:top>
                <v-dialog v-model="editDialog" max-width="500px" scrollable persistent>
                  <v-form v-model="editObjectValid" ref="form" @submit.prevent>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ editTitle }}</span>
                    </v-card-title>
                    <v-card-text style="height: 100%; overflow-y: auto;">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field dense v-model="editedItem.type" label="Device Type" disabled outlined></v-text-field>
                              <v-text-field dense v-model="editedItem.name" label="Device Name" outlined :rules="[rules.required]"></v-text-field>
                              <v-select
                                v-model="editedItem.clinicConnectorId"
                                :items="connectors"
                                item-text="name"
                                item-value="clinicConnectorId"
                                label="Clinic Connector"
                                :rules="[rules.requireguid]"
                                class="mt-4"
                                outlined
                                dense
                              ></v-select>
                              <v-text-field v-if="editedItem.type === 'WitnessDB'" dense outlined v-model="editedItem.instanceName" label="Instance Name" :rules="[rules.required]"></v-text-field>
                              <v-text-field v-if="editedItem.type === 'WitnessDB'" dense outlined v-model="editedItem.databaseName" label="Database Name"></v-text-field>
                              <v-checkbox v-if="editedItem.type === 'WitnessDB'" dense outlined v-model="editedItem.trustedConnection" label="Trusted Connection"></v-checkbox>
                              <v-text-field v-if="editedItem.type === 'WitnessDB'" dense outlined v-model="editedItem.userName" label="User Name" :disabled="editedItem.trustedConnection"></v-text-field>
                              <v-text-field v-if="editedItem.type === 'WitnessDB'" dense outlined v-model="editedItem.password" label="Password" :disabled="editedItem.trustedConnection"></v-text-field>
                              <v-text-field v-if="editedItem.type === 'G210'" dense outlined v-model="editedItem.host" label="IP Address" :rules="[rules.validIPv4]"></v-text-field>
                              <v-text-field v-if="editedItem.type === 'G210'" dense outlined v-model="editedItem.port" disabled label="Port Number"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <div v-if="editProcessing">
                        Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                      </div>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="editClose" :disabled="editProcessing">Cancel</v-btn>
                      <v-btn color="green accent-3" text @click="editSave" :disabled="!editObjectValid || (!configEdited && !nameEdited) || editProcessing" type="submit">Save</v-btn>
                    </v-card-actions>
                  </v-card>
                  </v-form>
                </v-dialog>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon
                  small
                  class='ml-2'
                  title="Info"
                  @click="showInfo(item)"
                >
                  $riwIconSet_info
                </v-icon>
                <v-icon
                  v-if="item.type === 'WitnessDB'  || item.type === 'G210'"
                  small
                  class='ml-2'
                  title="Edit"
                  @click="editItem(item)"
                >
                  $riwIconSet_edit
                </v-icon>
                <v-icon
                  v-if="item.lastReceivedTime === null"
                  small
                  class='ml-2'
                  title="Delete"
                  @click="deleteItem(item)"
                >
                  $riwIconSet_delete
                </v-icon>
              </template>
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name || item.deviceId || 'Unknown' }}
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-icon
                  v-if="item.status === 'Connected' || item.status === 'UpToDate'"
                  size=18
                  class='mr-2 pb-1'
                  color="green accent-3"
                >
                  $riwIconSet_connectionUp
                </v-icon>
                <v-icon
                  v-else-if="item.status==='ReceivingData'"
                  size=18
                  class='mr-2 pb-1'
                  color="green accent-3"
                >
                  $riwIconSet_receivingData
                </v-icon>
                <v-icon
                  v-else-if="item.status === 'Disabled'"
                  size=18
                  class='mr-2 pb-1'
                  color="amber accent-3"
                >
                  $riwIconSet_connectionUp
                </v-icon>
                <v-icon
                  v-else
                  size=18
                  class='mr-2 pb-1'
                  color="red accent-3"
                >
                  $riwIconSet_connectionDown
                </v-icon>
                {{ item.status === 'UpToDate' ? 'Up to date' : item.status === 'ReceivingData' ? 'Receiving data' : item.status === 'NotYetConnected' ? 'Not yet connected' : item.status }}
              </template>
              <template v-slot:[`item.permissions`]="{ item }">
                {{ item.permissions.join(", ") }}
              </template>
            </v-data-table>
            <v-dialog v-model="newDialog" persistent max-width="500px">
              <v-form ref="newDeviceForm" v-model="newObjectValid" @submit.prevent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ newTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                          <v-text-field dense v-model="newObject.name" label="Device Name" :rules="[rules.required]" outlined autofocus></v-text-field>
                        <v-select
                          v-model="newObject.type"
                          :items="deviceTypes"
                          :rules="[rules.oneWitnessDBAllowed]"
                          label="Device Type"
                          class="mt-4"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div v-if="newProcessing">
                    Processing...<v-progress-circular class="ml-2" indeterminate color="amber"/>
                  </div>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="newClose" :disabled="newProcessing">Cancel</v-btn>
                  <v-btn color="green accent-3" text @click="newCreate" :disabled="!newObjectValid || newProcessing" type="submit">Add</v-btn>
                </v-card-actions>
              </v-card>
              </v-form>
            </v-dialog>
            <v-dialog v-model="infoDialog" max-width="500px" scrollable @click:outside="closeInfo">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ infoTitle }}</span>
                </v-card-title>
                <v-card-text style="height: 100%; overflow-y: auto;">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-if="$store.getters.isSysAdmin" v-model="infoItem.deviceId" label="Device Id" readonly outlined dense class="ml-4 mr-4 pt-0"></v-text-field>
                        <v-text-field :value="this.infoItem.name || this.infoItem.deviceId || 'Unknown'" label="Device Name/ID" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <v-text-field v-if="this.infoItem.ipaddress" :value="this.infoItem.ipaddress" label="IP Address" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <v-text-field v-if="this.infoItem.type" :value="this.infoItem.type" label="Device Type" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <!-- <v-text-field :value="this.infoItem.status" label="Connector Status" disabled outlined dense rows=1 class="ml-4 mr-4"></v-text-field> -->
                        <v-text-field v-if="this.infoItem.lastReceivedTime" :value="this.infoItem.lastReceivedTime" label="Last Communication Time (UTC)" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <v-text-field v-if="this.infoItem.code" :value="this.infoItem.code" label="Connector Install Code" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <div class="text-center">
                          <div v-if="this.infoItem.status==='Connected' || this.infoItem.status==='UpToDate'">
                            <v-icon
                              size=100
                              class='ml-2'
                              title="Ok"
                              color="green accent-3"
                            >
                              $riwIconSet_connectionUp
                            </v-icon>
                          </div>
                          <div v-else-if="this.infoItem.status==='ReceivingData'">
                            <v-icon
                              size=100
                              class='ml-2'
                              title="Receiving Data"
                              color="green accent-3"
                            >
                              $riwIconSet_receivingData
                            </v-icon>
                          </div>
                          <div v-else-if="this.infoItem.status==='Disabled'">
                            <v-icon
                              size=100
                              class='ml-2'
                              color="amber accent-3"
                            >
                              $riwIconSet_connectionUp
                            </v-icon>
                          </div>
                          <div v-else>
                            <v-icon
                              size=100
                              class='ml-2'
                              title="Error"
                              color="red accent-3"
                            >
                              $riwIconSet_connectionDown
                            </v-icon>
                          </div>
                          <v-text-field v-if="this.infoItem.status && this.infoItem.status !== 'Error'" :value="this.infoItem.status" label="Status" readonly solo dense class="centered-input pl-12 pr-12 ml-12 mr-12 mt-4"></v-text-field>
                          <v-expansion-panels v-model="expansion" popout v-else-if="this.infoItem.status && this.infoItem.status === 'Error'"> <!--class="centered-input pl-12 pr-12 ml-0 mr-0 mt-4" -->
                            <v-expansion-panel class="ma-0 pa-0">
                              <v-expansion-panel-header><div style="text-align: center;">Status: {{this.infoItem.status}}</div></v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-text-field v-if="this.infoItem.errorTime" :value="this.infoItem.errorTime" label="Error Time (UTC)" readonly outlined dense class="centered-input mt-2 pt-0"></v-text-field>
                                <v-textarea v-if="this.infoItem.errorMessage" :value="this.infoItem.errorMessage" label="Error Message" outlined readonly class="centered-input"></v-textarea>
                                <v-text-field v-if="this.infoItem.errorName && $store.getters.isSysAdmin" :value="this.infoItem.errorName" label="Error Job Name" readonly outlined dense class="centered-input mt-2 pt-0"></v-text-field>
                                <v-textarea v-if="this.infoItem.errorString && $store.getters.isSysAdmin" :value="this.infoItem.errorString" label="Error String" outlined readonly class="centered-input ml-0 mr-0"></v-textarea>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>

                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeInfo">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-row>
              <v-checkbox v-model="emailAlerts" :indeterminate="emailAlertsLoading" :disabled="emailAlertsLoading" class="ma-0 ml-4" label="Subscribe to device email alerts" />
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

import adminDevicesViewModel from '@/view-models/admin-devices-view-model';

export default adminDevicesViewModel;

</script>